const homepage = {
    title: 'Home',
    first: "趣味を仕事にすることができた運者なので、それは必ず結果に出てくるんです。漫画翻訳専門家の私ですが、それ以上色々な勤務をしております。\n日本語·英語＞スペイン語·カタルーニャ語翻訳<br/>日本語·英語＞スペイン語·カタルーニャ語ポストエディット<br/>日本語·英語＞スペイン語·カタルーニャ語ローカライゼーション<br/>スペイン語·カタルーニャ語プルーフリーディング<br/>プロジェクトマネジメント<br/>言語教育",
    second: "他の言語を学ぶことが好きで、エマージングテクノロジー·コンピュター·ビデオゲームは実に興味深い。そして、日本文化とサブカルチャーの漫画·アニメの影響で来日することにし、何年間日本に住んでいたのである。その間はプロの翻訳者のスキルを仕込めたため、今はフリーランスとして漫画とアニメの翻訳営業を行っております。\n予算は[adriasaborido@gmail.com](mailto:adriasaborido@gmail.com)にて要求して下さい。早めにご返信いたします!<br/>CVは[こちら](https://drive.google.com/file/d/1s-YP0ktJOOkqDE_mfDiPoLyAYxxdBQc7/view?ts=6049ed07)からダウンロードしてご覧下さい\n[linkedin.com/in/adriasaboridovert](https://linkedin.com/in/adriasaboridovert)",
    manga: "漫画",
    anime: "アニメ",
    others: "その他",
    about: "私について",
}

export default homepage