import homepage from './homepage'
import projects from './projects'
import contact from './contact'
import error404 from './error404'

const es = {
    homepage,
    projects,
    contact,
    error404,
}

export default es
