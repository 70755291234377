const homepage = {
    title: 'Home',
    first: "Soy de esas pocas personas afortunadas que han podido transformar su hobby en su trabajo. ¡Y eso se nota en el resultado! Estoy especializado en la traducción de manga, pero sé hacer muchas otras cosas:\nTraducción ENG<span>/</span>JP<span> > </span>ESP<span>/</span>CAT<br/>Posedición ENG<span>/</span>JP<span> > </span>ESP<span>/</span>CAT<br/>Localización ENG<span>/</span>JP<span> > </span>ESP<span>/</span>CAT<br/>Corrección ESP<span>/</span>CAT<br/>Gestión de proyectos<br/>Enseñanza de idiomas",
    second: "Me encanta aprender idiomas y tengo mucho interés en las nuevas tecnologías, la informática y los videojuegos. Además, soy un amante de la cultura y la subcultura japonesa. Por ello acabé residiendo en Japón durante algunos años, en los que aprendí su idioma y cultura, a la vez que me formaba como traductor. Actualmente trabajo como autónomo traduciendo principalmente manga y anime.\nPara un presupuesto, escríbeme a [adriasaborido@gmail.com](mailto:adriasaborido@gmail.com). ¡Te atenderé enseguida!\n[Aquí](https://drive.google.com/file/d/1s-YP0ktJOOkqDE_mfDiPoLyAYxxdBQc7/view?ts=6049ed07) puedes descargarte mi currículum.<br/>\n[linkedin.com/in/adriasaboridovert](https://linkedin.com/in/adriasaboridovert)",
    manga: "Manga",
    anime: "Anime",
    others: "Otros",
    about: "Sobre mí",
}

export default homepage