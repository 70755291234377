const homepage = {
    title: 'Home',
    first: "I am one of those lucky ones who have been able to transform their hobby into their work. And that shows in the result! I am specialized in manga translation, but I can do much more things:\nTranslation ENG<span>/</span>JP<span> > </span>ESP<span>/</span>CAT<br/>Postediting ENG<span>/</span>JP<span> > </span>ESP<span>/</span>CAT<br/>Localization ENG<span>/</span>JP<span> > </span>ESP<span>/</span>CAT<br/>Proofreading ESP<span>/</span>CAT<br/>Project Management<br/>Language Teaching",
    second: "I love learning languages and I am very interested in new technologies, computers, and video games. I am also a lover of Japanese culture and subculture. That is why I ended up residing in Japan for a few years, during which I learned its language and culture, while I was training as a professional translator.<br/>Currently, I work as a freelancer mainly translating manga and anime.\nFor a quote, write me at [adriasaborido@gmail.com](mailto:adriasaborido@gmail.com) and I'll get back to you shortly!\n[Here](https://drive.google.com/file/d/1s-YP0ktJOOkqDE_mfDiPoLyAYxxdBQc7/view?ts=6049ed07) you can download my CV.<br/>\n[linkedin.com/in/adriasaboridovert](https://linkedin.com/in/adriasaboridovert)",
    manga: "Manga",
    anime: "Anime",
    others: "Others",
    about: "About me",
}

export default homepage